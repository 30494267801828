// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-solutions-after-sale-jsx": () => import("./../../../src/pages/our-solutions/after-sale.jsx" /* webpackChunkName: "component---src-pages-our-solutions-after-sale-jsx" */),
  "component---src-pages-our-solutions-e-ordering-jsx": () => import("./../../../src/pages/our-solutions/e-ordering.jsx" /* webpackChunkName: "component---src-pages-our-solutions-e-ordering-jsx" */)
}

